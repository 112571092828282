<template>
  <div v-bind="!collapsed ? wrapper: {}" :key="collapsed ? 'collaped' : 'opened'">
    <template v-if="collapsed">
      <slot name="open" :open-drawer="openDrawer"/>
      <a-drawer v-if="collapsed" v-bind="drawerProps" v-on="on">
        <slot name="content"/>
      </a-drawer>
    </template>

    <template v-else>
      <slot name="content"/>
    </template>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  collapsed: Boolean,
  drawer: {
    type: Object,
    default: () => ({
      placement: 'right'
    })
  },
  wrapper: {
    type: Object,
    default: () => ({})
  },
  show: {
    type: Boolean,
    default: false
  }
});

const visible = toRef(props.show);

watch(() => props.show, (value: boolean) => {
  visible.value = value;
});

const drawerProps = computed(() => ({
  visible: visible.value,
  ...props.drawer
}));

const emit = defineEmits(['close']);

const on = computed(() => {
  if (props.collapsed) {
    return {
      close: () => {
        if (typeof props.drawer.visible !== 'undefined') {
          emit('close');
        } else {
          visible.value = false;
        }
      }
    };
  }
});

function openDrawer() {
  visible.value = true;
}
</script>

