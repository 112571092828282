<template>
  <Icon v-if="customIcon" :style="iconStyle" fill="none" viewBox="0 0 24 24">
    <component :is="customIcon"/>
  </Icon>
  <component v-else :is="type"/>
</template>
<script lang="ts" setup>
import Apps from '@/assets/icons/apps.svg';
import Bag from '@/assets/icons/bag.svg';
import Cart from '@/assets/icons/cart.svg';
import Chart from '@/assets/icons/chart.svg';
import Message from '@/assets/icons/comments.svg';
import Document from '@/assets/icons/document.svg';
import eBay from '@/assets/icons/ebay.svg';
import Note from '@/assets/icons/note.svg';
import ArrowRight from '@/assets/icons/pfeil-rechts-3.svg';
import UserIcon from '@/assets/icons/portrait.svg';
import Product from '@/assets/icons/product.svg';
import Replied from '@/assets/icons/replied.svg';
import Send from '@/assets/icons/send.svg';
import Sent from '@/assets/icons/sent.svg';
import Settings from '@/assets/icons/settings.svg';
import Support from '@/assets/icons/support.svg';
import Translate from '@/assets/icons/translate.svg';
import Icon from '@ant-design/icons-vue';

const typeMap = {
  'replied': Replied,
  'sent': Sent,
  'send': Send,
  'eBay': eBay,
  'note': Note,
  'dashboard': Apps,
  'comments': Message,
  'cart': Cart,
  'bag': Bag,
  'support': Support,
  'user': UserIcon,
  'chart': Chart,
  'settings': Settings,
  'document': Document,
  'product': Product,
  'arrow-right': ArrowRight,
  'translate': Translate,
};

const props = defineProps({
  type: {
    type: [String, Function],
    required: true
  },
  fill: String
});

const customIcon = computed(() => typeMap[props.type]);
const iconStyle = computed(() => props.fill ? '--icon-color:' + props.fill : '');

</script>

